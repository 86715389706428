import { defineStore } from 'pinia';

import { Storage } from '@ionic/storage';
import { Router, useRouter } from 'vue-router';
import { loadingController } from '@ionic/vue';
import { useAppUtils } from '@/appUtils';

interface AppState {
  localStorage: Storage
  isLocaleLoaded: boolean
  isGetStartedSlide: boolean
  pageToShowOnLoad: string
  appAlreadyLoaded: boolean
  isAfterLoadingRunning: boolean
  router: Router,
  $i18n: any,
  user: any,
  isLoading: boolean,
  loadingCtrl: any,
  currentTab: string,
}

export const useAppState = defineStore('app-store', {
  state: (): AppState => (
    {
      localStorage: new Storage({
        name: '_cardee_db'
      }),
      isGetStartedSlide: false,
      isLocaleLoaded: false,
      pageToShowOnLoad: 'app-starting',
      appAlreadyLoaded: false,
      isAfterLoadingRunning: false,
      router: useRouter(),
      $i18n: 'en-US',
      user: {},
      isLoading: false,
      loadingCtrl: false,
      currentTab: 'tab_home',
    }
  ),
  actions: {
    setPageToShowOnLoad(pageName: string) {
      this.pageToShowOnLoad = pageName
    },
    setAsGetStartedSlide(value?: boolean) {
      this.isGetStartedSlide = value ?? true;
    },
    setI18n(i18n: any) {
      this.$i18n = i18n;
    },
    setLocaleLoaded(isLoaded: boolean) {
      this.isLocaleLoaded = isLoaded;
    },
    setAppAlreadyLoaded(isLoaded: boolean) {
      this.appAlreadyLoaded = isLoaded;
    },
    setIsAfterLoadingRunning(isRunning: boolean) {
      this.isAfterLoadingRunning = isRunning;
    },
    setUser(user: any) {
      this.user = user ?? null;
    },
    async setLoading(isLoading?: boolean, milliseconds?: number) {
      if (isLoading) {
        if (!this.isLoading) {
          this.isLoading = isLoading ?? true;

          this.loadingCtrl = await loadingController.create({
            message: '<img src="/assets/images/loading_spinner.gif" alt="" />',
            duration: milliseconds ?? 3000,
            spinner: null,
            cssClass: 'large',
          });
          
          await this.loadingCtrl.present();

          await this.loadingCtrl.onWillDismiss();

          //console.log('setLoading', isLoading, this.loadingCtrl);
        }
      }
      else {
        this.isLoading = isLoading ?? false;
        if (typeof this?.loadingCtrl?.dismiss === 'function') {
          await this.loadingCtrl.dismiss();
        }
        
        //console.log('setLoading', isLoading);
      }
    },
    setCurrentTab(newTab: string) {
      this.currentTab = newTab;
    },
    async setUserByLoginOrFromStorage() {
      const { isUserLoggedIn, getUserProfile } = useAppUtils();

      const areWeLoggedIn = await isUserLoggedIn();
      if (areWeLoggedIn) {
        const userProfile = await getUserProfile();
        if ((userProfile.status === 200) && (userProfile.data?.user_profile)) {
          this.setUser(userProfile.data);
        }
      }
    }
  }
});
