export const globalizationList = {
    en: {
        skip_intro: 'Skip intro',
        get_started: 'Get started',

        intro_previous: 'Previous',
        intro_next: 'Next',

        intro_1_line_1: 'Quick, easy, magical',
        intro_1_line_2: 'Digital Business Cards',
        intro_1_line_3: 'Create your own business cards without registering or subscribing.',

        intro_2_line_1: 'Shopping that simple',
        intro_2_line_2: 'All cards in one place',
        intro_2_line_3: 'Your loyalty cards in the palm of your hand so you never miss an offer.',

        intro_3_line_1: 'Keep your documents safe',
        intro_3_line_2: 'and always with you',
        intro_3_line_3: 'Avoid risks and keep your ID cards in a safe place, always close to you.',
        
        end_of_slide_line_1: 'To create or not to create an account',
        end_of_slide_line_2: 'You can decide!',
        end_of_slide_line_3: 'To share your cards you will need an account, you can create it now or maybe later if you wish.',
        end_of_slide_lets_begin: 'Let\'s begin!',
        start_app_with_signup: 'With signup',
        start_app_without_signup: 'Without signup',
        start_app_i_have_account: 'I have an account',

        tab_label_home: 'Home',
        tab_label_wallet: 'Wallet',
        tab_label_add: 'Add',
        tab_label_more: 'More',
        tab_label_settings: 'Settings',

        submit_error_occurred_title: 'Sorry',
        submit_error_occurred_text: 'An error has ocurred. Please try again.',
        submit_result_accessdenied_title: 'Access denied',
        submit_result_accessdenied_text: 'Please check that you are authorized and try again.',

        login_welcome_back_title: 'Log in here',
        login_welcome_back_text: 'Glad to have you back! Enter your email address and password to log in.',
        login_form_label_email: 'Email',
        login_form_required_email: 'Email is required',
        login_form_label_username: 'Username',
        login_form_required_username: 'Username is required',
        login_form_label_password: 'Password',
        login_form_required_password: 'Password is required',
        login_form_login_btn_caption: 'Log in',
        login_form_login_btn_caption_submitting: 'Logging in...',
        login_result_invalidcreds_title: 'Incorrect username or password',
        login_result_invalidcreds_text: 'Please check your information and try again.',
        login_no_account_question: 'Don\'t have an account?',
        login_sign_up: 'Sign up',
        login_forgot_password: 'Forgot Password?',
        login_error_reading_user_profile: 'Error reading user\'s profile:',
        login_error_trying_to_log_in: 'Error trying to log in:',

        reset_password_title: 'Don\'t remember your password?',
        reset_password_text: 'Enter your email or username to recover your account password.',
        reset_password_form_label_email: 'E-mail or username',
        reset_password_form_required_email: 'E-mail or username is required',
        reset_password_form_reset_btn_caption: 'Reset password',
        reset_password_popup_title: 'Your new password is on its way!',
        reset_password_popup_text: 'We have sent an email with a link to recover access to your Cardee account.',
        reset_password_popup_login_btn_label: 'Login',

        favorite_row_add: 'Add favorite',
        add_new_cardee_line_1: 'Create your first Cardee',
        add_new_cardee_line_2: 'Save a loyalty Card or a document',
        
        add_new_cardee_title: 'Let\'s create a Cardee',
        add_new_cardee_choices_add: 'Create a Cardee',
        add_new_cardee_choices_loyalty: 'Add a loyalty Card',
        add_new_cardee_choices_document: 'Add a document',

        add_new_cardee_go_pro_1: 'Go Pro now!',
        add_new_cardee_go_pro_2: 'Get to the next level in smart networking.',

        add_cardee_page_title: 'Let\'s get started!',
        add_cardee_page_info: 'Bring your Cardee to life by completing the information you want to share.',
        add_cardee_page_edit_title: 'Edit your Cardee',
        add_cardee_page_edit_info: 'Update your Cardee by changing the information below.',
        add_cardee_page_section_upload_images: 'Upload your images.',
        add_cardee_page_section_choose_background: 'Choose a background pattern.',
        add_cardee_page_section_choose_colors: 'Choose a color pattern.',
        add_cardee_page_section_enter_info: 'Enter your information.',
        add_cardee_page_section_select_social_media: 'Select Social Media profiles.',
        add_cardee_btn_upload_cardee_photo: 'Cardee photo',
        add_cardee_btn_upload_company_logo: 'Company logo',
        add_cardee_remove_image: 'Remove image',
        add_cardee_remove_logo: 'Remove logo',
        add_cardee_remove_photo: 'Remove photo',
        
        add_cardee_form_label_fullname: 'Full name',
        add_cardee_form_label_companyname: 'Company name',
        add_cardee_form_label_jobposition: 'Job position',
        add_cardee_form_label_shortdescription: 'Short description',
        add_cardee_form_label_mobilenumber: 'Mobile phone',
        add_cardee_form_label_whatsappnumber: 'Whatsapp number',
        add_cardee_form_label_officenumber: 'Work phone',
        add_cardee_form_label_emailaddress: 'E-mail address',
        add_cardee_form_label_personalemail: 'Personal e-mail',
        add_cardee_form_label_workemail: 'Work e-mail',
        add_cardee_form_label_weburl: 'Web site',
        add_cardee_form_label_personalwebsite: 'Personal website',
        add_cardee_form_label_companywebsite: 'Company website',
        add_cardee_form_btn_caption_submit: 'Create Cardee',
        add_cardee_form_btn_caption_submitting: 'Creating...',
        add_cardee_form_btn_caption_update: 'Update Cardee',
        add_cardee_form_btn_caption_updating: 'Updating...',
        add_cardee_form_btn_caption_delete: 'Delete Cardee',
        add_cardee_form_btn_caption_deleting: 'Deleting...',
        add_cardee_social_btn_save: 'Save',
        add_cardee_success: 'Your new Cardee is ready',
        add_cardee_cardee_updated: 'Your Cardee has been updated.',
        add_cardee_cardee_deleted: 'Your Cardee has been deleted.',

        cardee_page_btn_caption_edit: 'Edit',
        cardee_page_no_cardee_id: 'There is no Cardee with the following ID: {cardId}',
        cardee_page_no_cardee_handle: 'There is no Cardee with the following handle: {cardHandle}',
        cardee_page_not_available: 'Not available.',

        opening_the_cardee: 'Opening the Cardee...',
        page_not_found: 'Not found',
        please_go_back_and_try_again: 'Please go back and try again.',

        social_form_field_instagram: '{\'@\'}Instagram handle',
        social_form_field_facebook: 'Facebook profile',
        social_form_field_linkedin: 'LinkedIn profile',
        social_form_field_xtwitter: '{\'@\'}X/Twitter handle',
        social_form_field_tiktok: '{\'@\'}Tik Tok handle',
        social_form_field_telegram: '{\'@\'}Telegram handle',
        social_form_field_pinterest: 'Pinterest profile',
        social_form_field_youtube: 'Youtube channel',

        wallet_page_segment_cardee: 'Cardee',
        wallet_page_segment_loyalty: 'Loyalty',
        wallet_page_segment_docs: 'Docs',
        wallet_page_segment_cardee_my_cardees: 'My Cardees',
        wallet_page_segment_cardee_others_cardee: 'Other Cardees',
        wallet_page_add_new_cardee: 'Add new Cardee',
        wallet_page_scan_a_cardee: 'Scan a Cardee',
        wallet_page_add_new_loyalty: 'Add new loyalty',
        wallet_page_add_new_document: 'Add new document',
        wallet_page_cardees_in_your_device_upload_them: 'You have NO Cardees in your device. | You have 1 Cardee in your device. \nUpload it to your Wallet? | You have {n} Cardees in your device. \nUpload them to your Wallet?',
        wallet_page_all_local_cardees_transferred_to_your_wallet: 'All the local Cardees were transferred to your Wallet',
        wallet_page_upload_cardees_title: 'Upload local Cardees',
        wallet_page_upload_cardees_question: 'Are you sure you want to upload them?',

        settings_page_segment_hello: 'Hello',
        settings_page_segment_me: 'Me',
        settings_page_segment_settings: 'Settings',
        settings_page_segment_profile: 'Profile',
        settings_page_segment_support: 'Support',
        settings_page_segment_settings_notifications: 'Notifications',
        settings_page_segment_settings_change_password: 'Change password',
        settings_page_segment_settings_privacy: 'Privacy',
        settings_page_segment_settings_security: 'Security',
        settings_page_segment_profile_become_a_pro: 'Become a Pro',
        settings_page_segment_profile_personal_profile: 'Personal profile',
        settings_page_segment_profile_account: 'Account',
        settings_page_segment_profile_invite_your_friends: 'Invite your Friends',
        settings_page_segment_profile_logout: 'Logout',
        settings_page_segment_profile_login: 'Login',
        settings_page_segment_support_faq: 'FAQs',
        settings_page_segment_support_faq_description: 'Frequently asked questions about the use of the application.',
        settings_page_segment_support_contact_support: 'Contact support',
        settings_page_segment_support_contact_support_description: 'Technical support and customer service.',
        settings_page_segment_support_information: 'Information',
        settings_page_segment_support_information_description: 'Version, changes and improvements in the application.',
        settings_page_segment_support_version: 'Version',

        cardee_btn_already_in_wallet: 'This Cardee is in your Wallet',
        cardee_btn_add_to_wallet: 'Add this Cardee to your Wallet',
        cardee_btn_add_to_contacts: 'Add to contacts',
        cardee_btn_share_cardee: 'Share Cardee',
        cardee_btn_share_with_nfc: 'Share with NFC',
        cardee_btn_close_cardee: 'Close Cardee',
        cardee_btn_remove_cardee_from_wallet: 'Remove Cardee',
        cardee_btn_removing: 'Removing...',
        cardee_added_to_your_wallet: 'Cardee added to your Wallet!',
        cardee_removed_from_your_wallet: 'The Cardee has been successfully removed from your Wallet.',

        coming_soon: 'Coming soon!',
        under_development: 'Under development',
        this_feature_under_development: 'This feature is under development!',
        this_feature_will_be_available_shortly: 'This feature will be available shortly!',

        popup_create_account_title: 'Create an account to share',
        popup_create_account_description: 'To be able to share your cards you need to create an account, it\'s easy and it\'s free for life',
        popup_create_account_its_free: 'it\'s free',
        popup_create_account_btn_create_an_account: 'Create an account',
        popup_create_account_go_pro: 'You can also go pro for unlimited experience',

        register_welcome_title: 'Register here!',
        register_welcome_text: 'Create a free Cardee account.',
        register_or: 'OR',
        register_have_an_account: 'Do you have an account?',
        register_btn_register: 'Register',
        register_link_sign_in: 'Sign in',
        register_form_label_full_name: 'Full name',
        register_form_required_full_name: 'Full name is required',
        register_form_label_username: 'Username',
        register_form_required_username: 'Username is required',
        register_form_label_email: 'Email',
        register_form_required_email: 'Email is required',
        register_form_label_password: 'Password',
        register_form_required_password: 'Password is required',
        register_form_label_profile_image: 'Profile picture',
        register_form_btn_continue_with_google: 'Continue with Google',
        register_form_btn_continue_with_apple: 'Continue with Apple',
        register_form_btn_continue_with_facebook: 'Continue with Facebook',

        account_created_title: 'Your account has been created!',
        account_created_text: 'Now you can use some additional cool functions.',
        account_created_btn_get_started: 'Get started',

        logout_question: 'Are you sure you want to go out?',
        logout_label_exit: 'Exit',
        logout_label_cancel: 'Cancel',
        logout_success: 'You have been logged out',

        popup_add_card_title: 'First add a card to wallet',
        popup_add_card_description: 'To pin your cards as a favorites you must first have added some to the Wallet.',
        popup_add_card_lets_do_it: 'Let\'s do it!',
        popup_add_card_btn_add_card: 'Add a card to wallet',

        onboarding_1_line_1: 'Now you can share',
        onboarding_1_line_2: 'UNLIMITED',
        onboarding_1_line_3: 'Create your business cards and share them with all the people you want.',

        onboarding_2_line_1: 'Save Cardee in',
        onboarding_2_line_2: 'iOS or Android Wallet',
        onboarding_2_line_3: 'People can save your Cardee to their wallets without creating an account.',

        onboarding_3_line_1: 'Share easily and super fast',
        onboarding_3_line_2: 'Contactless via NFC',
        onboarding_3_line_3: 'Bring your smartphone to another and voilà! Your Cardee is automatically shared.',

        next: 'Next',

        home_page_hello: 'Hello',
        home_page_user: 'User',

        popup_share_description: 'or select a message service to share',

        popup_remove_title: 'Remove item',
        popup_remove_question: 'Are you sure you want to remove this?',
        popup_btn_label_yes: 'Yes',
        popup_btn_label_no: 'No',
        popup_btn_label_ok: 'OK',
        popup_btn_label_cancel: 'Cancel',
        popup_remove_success: 'Item has been removed',
        popup_close_page_title: 'Close without saving',
        popup_close_page_question: 'Are you sure you want to leave without saving the changes?',
        popup_delete_title: 'Delete item',
        popup_delete_question: 'Are you sure you want to delete this?',
        popup_delete_cardee_title: 'Delete Cardee',
        popup_delete_cardee_question: 'Are you sure you want to delete this Cardee?',
        popup_remove_cardee_from_wallet_title: 'Remove Cardee',
        popup_remove_cardee_from_wallet_question: 'Are you sure you want to remove this Cardee from your Wallet?',
        popup_scan_qr_code_title: 'Scan QR code',
        popup_scan_qr_code_question: 'Are you sure you want to scan a QR code?',
        popup_camera_grant_permission_title: 'Grant permission', 
        popup_camera_grant_permission_question: 'If you want to grant permission for using your camera, enable it in the app settings.',
        popup_grant_permission_scan_qr_codes_title: 'Grant permission',
        popup_grant_permission_scan_qr_codes_question: 'We need your permission to use your phone camera to be able to scan QR codes',
        popup_deactivate_account_title: 'Deactivate account',
        popup_deactivate_account_question: 'Are you sure you want to deactivate your account?',
        popup_delete_account_title: 'Delete account',
        popup_delete_account_question: 'Are you sure you want to delete your account?',

        change_password_title: 'Change password',
        change_password_old: 'Old password',
        change_password_old_placeholder: 'Your old password',
        change_password_new: 'New password',
        change_password_new_placeholder: 'Your new password',
        change_password_retype: 'Retype password',
        change_password_retype_placeholder: 'Retype your new password',
        change_password_passwords_do_not_match: 'New and retyped passwords do not match',
        change_password_btn_save_new_password: 'Save new password',
        change_password_changes_saved: 'Your new password have been saved.',
        change_password_this_field_is_required: 'This field is required',

        profile_page_me: 'Me',
        profile_page_edit_profile: 'Edit profile',
        profile_page_friends: 'Friends',
        profile_page_leads: 'Leads',
        profile_page_deals: 'Deals',
        profile_page_my_bio: 'My bio',
        profile_page_city: 'City',
        profile_page_country: 'Country',
        profile_page_workplace: 'Workplace',
        profile_page_what_i_love: 'What I love',
        profile_page_my_web: 'My web',

        profile_editor_title: 'Profile settings',
        profile_editor_btn_change_photo: 'Change photo',
        profile_editor_btn_add_photo: 'Add photo',
        profile_editor_btn_remove_photo: 'Remove photo',
        profile_editor_btn_save_profile: 'Save profile',
        profile_editor_display_name: 'Display name',
        profile_editor_display_name_placeholder: 'Your name for public display',
        profile_editor_presentation: 'Presentation',
        profile_editor_presentation_placeholder: 'Write something cool about you',
        profile_editor_city_where_you_live: 'City where you live',
        profile_editor_city_where_you_live_placeholder: 'Some pretty city in the word',
        profile_editor_country: 'Country',
        profile_editor_country_placeholder: 'The land where you currently live',
        profile_editor_workplace: 'Workplace',
        profile_editor_workplace_placeholder: 'Somewhere to be productive',
        profile_editor_what_i_love: 'What do you like the most?',
        profile_editor_what_i_love_placeholder: 'Any hobby or activity',
        profile_editor_web_page_or_link: 'Web page or link',
        profile_editor_web_page_or_link_placeholder: 'Any profile or blog link',
        profile_editor_this_field_is_required: 'This field is required',
        profile_editor_changes_saved: 'Your changes have been saved.',

        settings_notifications_title: 'Notification settings',
        settings_notifications_notifications: 'Notifications',
        settings_notifications_contact_notification: 'Contact notification',
        settings_notifications_app_updates: 'App Updates',
        settings_notifications_news_and_promotion: 'News &amp; Promotion',
        settings_notifications_new_functions_available: 'New functions Available',
        settings_notifications_changes_saved: 'Your changes have been saved.',

        account_settings_title: 'Account settings',
        account_settings_personal_information: 'Personal information',
        account_settings_language: 'Language',
        account_settings_synchronize_contacts: 'Synchronize contacts',
        account_settings_delete_account: 'Delete account',

        personal_information_title: 'Personal information',
        personal_information_edit_title: 'Edit information',
        personal_information_username: 'Username',
        personal_information_username_placeholder: 'Enter your username',
        personal_information_full_name: 'Full name',
        personal_information_full_name_placeholder: 'Enter your full name',
        personal_information_first_name: 'First name',
        personal_information_first_name_placeholder: 'Enter your first name',
        personal_information_last_name: 'Last name',
        personal_information_last_name_placeholder: 'Enter your last name',
        personal_information_email: 'E-mail',
        personal_information_email_placeholder: 'Enter your email address',
        personal_information_mobile: 'Mobile phone',
        personal_information_mobile_placeholder: 'Enter your mobile number',
        personal_information_birthday: 'Birthday',
        personal_information_birthday_placeholder: 'Enter your date of birth',
        personal_information_country: 'Country',
        personal_information_country_placeholder: 'Country of residence',
        personal_information_this_field_is_required: 'This field is required',
        personal_information_btn_edit_personal_data: 'Edit personal data',
        personal_information_btn_save_personal_data: 'Save personal data',

        settings_language_title: 'Language',
        settings_language_description: 'Choose your language preferences',
        settings_language_main_language: 'Main language',
        settings_language_translator: 'Translator',

        settings_delete_account_title: 'Delete account',
        settings_delete_account_title_long: 'Deactivate or delete your account?',
        settings_delete_account_deactivation: 'Account deactivation is temporary.',
        settings_delete_account_deactivation_description: 'Your profile, cards, messages, and saved information are hidden until you turn it back on or sign in again.',
        settings_delete_account_deletion: 'The deletion of the account is final.',
        settings_delete_account_deletion_description: 'Your profile, cards, messages and all saved information will be completely deleted.',
        settings_delete_account_btn_deactivate_account: 'Deactivate account',
        settings_delete_account_btn_delete_account: 'Delete account',

        settings_account_has_been_deactivated: 'Your account has been deactivated.',
        settings_account_has_been_deleted: 'Your account has been deleted.',

        help_page_review_title: 'How much do you like Cardee?',
        help_page_review_star1_title: 'We are very, very sorry!',
        help_page_review_star1_description: 'Share your impressions, we will do our best to improve.',
        help_page_review_star2_title: 'Are we doing something wrong?',
        help_page_review_star2_description: 'Tell us what you don\'t like and we\'ll work to improve it.',
        help_page_review_star3_title: 'Apparently you are not very convinced!',
        help_page_review_star3_description: 'Tell us what we can do to make you feel better.',
        help_page_review_star4_title: 'It seems like we are doing things right!',
        help_page_review_star4_description: 'but we can always do better! Thank you very much.',
        help_page_review_star5_title: 'it\'s amazing what we\'re doing together',
        help_page_review_star5_description: 'Thank you very much, we want to keep improving, help us a little.',
        help_page_review_btn_help_us_to_improve: 'Help us improve',
        help_page_help_us_to_improve_header: 'Help us improve',
        help_page_help_us_to_improve_note: 'What else would you like us to do?',
        help_page_help_us_to_improve_title: 'We can always do it better.',
        help_page_take_the_survey: 'Take the survey',
        help_page_share_and_win_header: 'Share and win!',
        help_page_share_and_win_note: 'Invite your friends and get more.',
        help_page_invite_5: 'Invite 5',
        help_page_win_6: 'Win 6!',
        help_page_invite_your_friens: 'Invite your friends and become a',
        help_page_pro: 'Pro',
        help_page_share_with_your_friends: 'Share with your friends.',
        help_page_ad_smart_networking: '#SmartNetworking',
        help_page_ad_text_line_1: 'Easy to create',
        help_page_ad_text_line_2: 'Easy to share',
        help_page_ad_text_line_3: 'Easy to store',
        help_page_ad_without_a_doubt_it_is: 'Without a doubt it is',
        help_page_ad_lets_connect: 'Let\'s connect!',

        notifications_title: 'Notifications',

        photo_gallery_choose_a_photo: 'Choose a photo',
        photo_gallery_camera_roll: 'Camera roll',
        photo_gallery_albums: 'Albums',
        photo_gallery_cardee_photos: 'Cardee photos',
        photo_gallery_chose_your_company_logo: 'Choose your company logo',
        photo_gallery_selected_album: 'Selected Album:',
        photo_gallery_take_your_photo: 'Take your photo',
        photo_gallery_for_best_results: 'For best results',
        photo_gallery_prompt_photo: 'Photo',
        photo_gallery_prompt_cancel: 'Cancel',
        photo_gallery_prompt_from_gallery: 'From Gallery',
        photo_gallery_prompt_take_picture: 'Take Picture',

        error_something_went_wrong: 'Something went wrong!',
        error_could_not_reach_the_server: 'Could not reach the server!',
        error_max_cards_limit_reached: 'The maximum number of Cardees reached.',
        error_permission_denied: 'Permisssion denied!',

        error_invalid_email: 'Invalid email',
        error_invalid_phone_number: 'Invalid phone number',
        error_login_btn_label: 'Login',
        error_dismiss_btn_label: 'Dismiss',
        error_close_btn_label: 'Close',
        error_no_permission_to_this_operation: 'You don\'t have permission to perform this operation.',

        information_title: 'Information',
        information_app_version: 'App version',
        information_compilation: 'Compilation',
        information_copyright: 'Copyright 2023',
        information_company_name: 'Cardee Technologies, SL',
        information_all_rights_reserved: 'All rights reserved',

        privacy_description: 'Cardee Technologies, SL. applies the policy of complying with all applicable privacy and data protection laws. This commitment reflects the value we place on earning and keeping the trust of our customers, business partners, and others who share their personal information with us.',
        privacy_btn_label: 'Cardee Privacy',

        its_ok_btn_label: 'It\'s OK',
    },
    es: {
        skip_intro: 'Saltar intro',
        get_started: 'Comenzar',

        intro_previous: 'Anterior',
        intro_next: 'Próxima',

        intro_1_line_1: 'Rápida, fácil, mágica',
        intro_1_line_2: 'Tarjetas de Presentación Digitales',
        intro_1_line_3: 'Crea tu propias tarjetas de presentación sin registrarte.',

        intro_2_line_1: 'De compras así de simple',
        intro_2_line_2: 'Todas las tarjetas en un solo lugar',
        intro_2_line_3: 'Tus tarjetas de fidelidad en la palma de tu mano para que nunca te pierdas de una oferta.',

        intro_3_line_1: 'Tus documentos están seguros',
        intro_3_line_2: 'siempre disponibles para tí',
        intro_3_line_3: 'Evita riesgos y guarda tus carnets de identificación en un sitio seguro, y siempre cerca de tí.',
        
        end_of_slide_line_1: 'Crear o no crear una cuenta',
        end_of_slide_line_2: '¡Es tu decisión!',
        end_of_slide_line_3: 'Necesitarás una cuenta para poder compartir tus Cardees con otras personas. Pero no tienes que crearla en este momento si no lo prefieres.',
        end_of_slide_lets_begin: '¡Comencemos!',
        start_app_with_signup: 'Con registro',
        start_app_without_signup: 'Sin registro',
        start_app_i_have_account: 'Ya tengo una cuenta',

        tab_label_home: 'Inicio',
        tab_label_wallet: 'Cartera',
        tab_label_add: 'Añadir',
        tab_label_more: 'Más',
        tab_label_settings: 'Ajustes',

        submit_error_occurred_title: 'Perdona',
        submit_error_occurred_text: 'Ha ocurrido un error. Por favor inténtalo de nuevo.',
        submit_result_accessdenied_title: 'Acceso denegado',
        submit_result_accessdenied_text: 'Por favor verifica que estás autorizado e inténtalo de nuevo.',

        login_welcome_back_title: 'Inicia sesión',
        login_welcome_back_text: '¡Qué bueno tenerte de vuelta! Introduce tu correo electrónico y contraseña para ingresar.',
        login_form_label_email: 'Correo',
        login_form_required_email: 'Se requiere el correo',
        login_form_label_username: 'Nombre de usuario',
        login_form_required_username: 'Se requiere el nombre de usuario',
        login_form_label_password: 'Contraseña',
        login_form_required_password: 'Se requiere la contraseña',
        login_form_login_btn_caption: 'Ingresar',
        login_form_login_btn_caption_submitting: 'Ingresando...',
        login_result_invalidcreds_title: 'Nombre de usuario o contraseña incorrectos',
        login_result_invalidcreds_text: 'Por favor verifica tu información e inténtalo de nuevo.',
        login_no_account_question: '¿No tienes una cuenta?',
        login_sign_up: 'Regístrate',
        login_forgot_password: '¿Olvidaste tu contraseña?',
        login_error_reading_user_profile: 'Error leyendo el perfil del usuario:',
        login_error_trying_to_log_in: 'Error al intentar ingresar:',

        reset_password_title: '¿Olvidaste tu contraseña?',
        reset_password_text: 'Ingresa tu correo para recuperar tu contraseña.',
        reset_password_form_label_email: 'Correo',
        reset_password_form_required_email: 'Se requiere el correo',
        reset_password_form_reset_btn_caption: 'Cambiar contraseña',
        reset_password_popup_title: '¡Tu contraseña va en camino!',
        reset_password_popup_text: 'Te hemos enviado un correo con un enlace para recuperar el acceso a tu cuenta de Cardee.',
        reset_password_popup_login_btn_label: 'Ingresar',

        favorite_row_add: 'Agregar favorito',
        add_new_cardee_line_1: 'Crea tu primera Cardee',
        add_new_cardee_line_2: 'Guarda una Tarjeta de fidelidad o un documento',
        
        add_new_cardee_title: 'Vamos a crear una Cardee',
        add_new_cardee_choices_add: 'Crea una Cardee',
        add_new_cardee_choices_loyalty: 'Agrega una Tarjeta de fidelidad',
        add_new_cardee_choices_document: 'Agrega un documento',

        add_new_cardee_go_pro_1: '¡Suscríbete a Pro!',
        add_new_cardee_go_pro_2: 'Alcanza el siguiente nivel en smart networking.',

        add_cardee_page_title: '¡Comencemos!',
        add_cardee_page_info: 'Dale vida a tu Cardee al completar la información que deseas compartir.',
        add_cardee_page_edit_title: 'Edita tu Cardee',
        add_cardee_page_edit_info: 'Actualiza tu Cardee cambiando la información de abajo.',
        add_cardee_page_section_upload_images: 'Sube tus imágenes.',
        add_cardee_page_section_choose_background: 'Escoge un patrón para el fondo.',
        add_cardee_page_section_choose_colors: 'Escoge un patrón de colores.',
        add_cardee_page_section_enter_info: 'Introduce tu información.',
        add_cardee_page_section_select_social_media: 'Selecciona los perfiles de Redes Sociales.',
        add_cardee_btn_upload_cardee_photo: 'Foto Cardee',
        add_cardee_btn_upload_company_logo: 'Logo de la compañía',
        add_cardee_remove_image: 'Eliminar imagen',
        add_cardee_remove_logo: 'Eliminar logo',
        add_cardee_remove_photo: 'Eliminar foto',
        
        add_cardee_form_label_fullname: 'Nombre completo',
        add_cardee_form_label_companyname: 'Nombre de la compañía',
        add_cardee_form_label_jobposition: 'Posición',
        add_cardee_form_label_shortdescription: 'Descripción breve',
        add_cardee_form_label_mobilenumber: 'Teléfono móvil',
        add_cardee_form_label_whatsappnumber: 'Whatsapp',
        add_cardee_form_label_officenumber: 'Teléfono oficina',
        add_cardee_form_label_emailaddress: 'Dirección de correo',
        add_cardee_form_label_personalemail: 'Email personal',
        add_cardee_form_label_workemail: 'Email de trabajo',
        add_cardee_form_label_weburl: 'Sitio web',
        add_cardee_form_label_personalwebsite: 'Sitio web personal',
        add_cardee_form_label_companywebsite: 'Sitio web de la compañía',
        add_cardee_form_btn_caption_submit: 'Crear Cardee',
        add_cardee_form_btn_caption_submitting: 'Creando...',
        add_cardee_form_btn_caption_update: 'Actualizar Cardee',
        add_cardee_form_btn_caption_updating: 'Actualizando...',
        add_cardee_form_btn_caption_delete: 'Eliminar Cardee',
        add_cardee_form_btn_caption_deleting: 'Eliminando...',
        add_cardee_social_btn_save: 'Guardar',
        add_cardee_success: 'Tu Cardee nueva está lista',
        add_cardee_cardee_updated: 'Tu Cardee ha sido actualizada.',
        add_cardee_cardee_deleted: 'Tu Cardee ha sido eliminada.',

        cardee_page_btn_caption_edit: 'Editar',
        cardee_page_no_cardee_id: 'No hay ninguna Cardee con el siguiente ID: {cardId}',
        cardee_page_no_cardee_handle: 'No hay ninguna Cardee con el siguiente handle: {cardHandle}',
        cardee_page_not_available: 'No disponible.',

        opening_the_cardee: 'Abriendo la Cardee...',
        page_not_found: 'No encontrado',
        please_go_back_and_try_again: 'Por favor regresa e inténtalo de nuevo.',

        social_form_field_instagram: '{\'@\'}Usuario de Instagram',
        social_form_field_facebook: 'Perfil de Facebook',
        social_form_field_linkedin: 'Perfil de LinkedIn',
        social_form_field_xtwitter: '{\'@\'}Usuario de X/Twitter',
        social_form_field_tiktok: '{\'@\'}Usuario de Tik Tok',
        social_form_field_telegram: '{\'@\'}Usuario de Telegram',
        social_form_field_pinterest: 'Perfil de Pinterest',
        social_form_field_youtube: 'Canal de Youtube',

        wallet_page_segment_cardee: 'Cardee',
        wallet_page_segment_loyalty: 'Fidelidad',
        wallet_page_segment_docs: 'Documentos',
        wallet_page_segment_cardee_my_cardees: 'Mis Cardees',
        wallet_page_segment_cardee_others_cardee: 'Otras Cardees',
        wallet_page_add_new_cardee: 'Agregar nueva Cardee',
        wallet_page_scan_a_cardee: 'Escanear una Cardee',
        wallet_page_add_new_loyalty: 'Agregar nueva tarjeta fidelidad',
        wallet_page_add_new_document: 'Agregar nuevo documento',
        wallet_page_cardees_in_your_device_upload_them: 'No tienes NINGUNA Cardee en tu dispositivo. | Tienes 1 Cardee en tu dispositivo. \n¿La subimos a tu Cartera? | Tienes {n} Cardees en tu dispositivo. \n¿La subimos a tu Cartera?',
        wallet_page_all_local_cardees_transferred_to_your_wallet: 'Todas las Cardees locales se transfirieron a tu Cartera',
        wallet_page_upload_cardees_title: 'Subir Cardees locales',
        wallet_page_upload_cardees_question: '¿Confirmas que deseas subirlas?',
        
        settings_page_segment_hello: 'Hola',
        settings_page_segment_me: 'Me', // Leave as "Me" (untranslated.) By design.
        settings_page_segment_settings: 'Ajustes',
        settings_page_segment_profile: 'Perfil',
        settings_page_segment_support: 'Soporte',
        settings_page_segment_settings_notifications: 'Notificaciones',
        settings_page_segment_settings_change_password: 'Cambiar contraseña',
        settings_page_segment_settings_privacy: 'Privacidad',
        settings_page_segment_settings_security: 'Seguridad',
        settings_page_segment_profile_become_a_pro: 'Sé un Pro',
        settings_page_segment_profile_personal_profile: 'Perfil personal',
        settings_page_segment_profile_account: 'Cuenta',
        settings_page_segment_profile_invite_your_friends: 'Invita a tus amigos',
        settings_page_segment_profile_logout: 'Cerrar sesión',
        settings_page_segment_profile_login: 'Ingresar',
        settings_page_segment_support_faq: 'Preguntas frecuentes',
        settings_page_segment_support_faq_description: 'Preguntas frecuentes sobre el uso de la aplicación.',
        settings_page_segment_support_contact_support: 'Contactar soporte',
        settings_page_segment_support_contact_support_description: 'Soporte técnico y servicio al cliente.',
        settings_page_segment_support_information: 'Información',
        settings_page_segment_support_information_description: 'Versión, cambios y mejoras en la aplicación.',
        settings_page_segment_support_version: 'Versión',
        
        cardee_btn_already_in_wallet: 'Esta Cardee ya está en tu Cartera',
        cardee_btn_add_to_wallet: 'Añadir esta Cardee a tu Cartera',
        cardee_btn_add_to_contacts: 'Añadir a contactos',
        cardee_btn_share_cardee: 'Compartir Cardee',
        cardee_btn_share_with_nfc: 'Compartir con NFC',
        cardee_btn_close_cardee: 'Cerrar Cardee',
        cardee_btn_remove_cardee_from_wallet: 'Eliminar Cardee',
        cardee_btn_removing: 'Eliminando...',
        cardee_added_to_your_wallet: '¡Cardee añadida a tu Cartera!',
        cardee_removed_from_your_wallet: 'La Cardee ha sido eliminada correctamente de tu Cartera.',
        
        coming_soon: '¡Próximamente!',
        under_development: 'En desarrollo',
        this_feature_under_development: '¡Esta función está en desarrollo!',
        this_feature_will_be_available_shortly: '¡Esta funcionalidad estará disponible pronto!',
        
        popup_create_account_title: 'Crea una cuenta para compartir',
        popup_create_account_description: 'Para poder compartir tus tarjetas, necesitas crear una cuenta. Es fácil y gratuita de por vida.',
        popup_create_account_its_free: 'es gratis',
        popup_create_account_btn_create_an_account: 'Crear una cuenta',
        popup_create_account_go_pro: 'También puedes volverte Pro para obtener una experiencia sin límites',
        
        register_welcome_title: 'Regístrate',
        register_welcome_text: 'Crea una cuenta Cardee gratuita.',
        register_or: 'O también',
        register_have_an_account: '¿Ya tienes una cuenta?',
        register_btn_register: 'Registrarse',
        register_link_sign_in: 'Iniciar sesión',
        register_form_label_full_name: 'Nombre completo',
        register_form_required_full_name: 'Se requiere el nombre completo',
        register_form_label_username: 'Nombre de usuario',
        register_form_required_username: 'Se requiere el nombre de usuario',
        register_form_label_email: 'Correo electrónico',
        register_form_required_email: 'Se requiere el correo electrónico',
        register_form_label_password: 'Contraseña',
        register_form_required_password: 'Se requiere la contraseña',
        register_form_label_profile_image: 'Imagen de perfil',
        register_form_btn_continue_with_google: 'Continuar con Google',
        register_form_btn_continue_with_apple: 'Continuar con Apple',
        register_form_btn_continue_with_facebook: 'Continuar con Facebook',
        
        account_created_title: '¡Se ha creado tu cuenta!',
        account_created_text: 'Ahora puedes utilizar algunas funciones adicionales fenomenales.',
        account_created_btn_get_started: 'Comenzar',
        
        logout_question: '¿De verdad quieres salir?',
        logout_label_exit: 'Salir',
        logout_label_cancel: 'Cancelar',
        logout_success: 'Has cerrado la sesión correctamente',
        
        popup_add_card_title: 'Agrega primero una tarjeta a la Cartera',
        popup_add_card_description: 'Para fijar tus tarjetas como favoritas, primero debes agregar algunas a la Cartera.',
        popup_add_card_lets_do_it: '¡Hagámoslo!',
        popup_add_card_btn_add_card: 'Agregar a la Cartera',
        
        onboarding_1_line_1: 'Ahora puedes compartir',
        onboarding_1_line_2: 'ILIMITADAMENTE',
        onboarding_1_line_3: 'Crea tus tarjetas de negocio y compártelas con todas las personas que desees.',
        
        onboarding_2_line_1: 'Guarda Cardee en',
        onboarding_2_line_2: 'iOS o Android Wallet',
        onboarding_2_line_3: 'Las personas pueden guardar tu Cardee en sus carteras sin crear una cuenta.',
        
        onboarding_3_line_1: 'Comparte fácil y súper rápido',
        onboarding_3_line_2: 'Sin contacto vía NFC',
        onboarding_3_line_3: '¡Acerca tu teléfono a otro, y listo! Tu Cardee se comparte automáticamente.',
        
        next: 'Siguiente',
        
        home_page_hello: 'Hola',
        home_page_user: 'Usuario',
        
        popup_share_description: 'o selecciona un servicio de mensajería para compartir',
        
        popup_remove_title: 'Eliminar artículo',
        popup_remove_question: '¿De verdad deseas eliminar esto?',
        popup_btn_label_yes: 'Sí',
        popup_btn_label_no: 'No',
        popup_btn_label_ok: 'Aceptar',
        popup_btn_label_cancel: 'Cancelar',
        popup_remove_success: 'El artículo ha sido eliminado',
        popup_close_page_title: 'Cerrar sin guardar',
        popup_close_page_question: '¿De verdad deseas salir sin guardar los cambios?',
        popup_delete_title: 'Eliminar artículo',
        popup_delete_question: '¿De verdad deseas eliminar esto?',
        popup_delete_cardee_title: 'Eliminar Cardee',
        popup_delete_cardee_question: '¿De verdad deseas eliminar esta Cardee?',
        popup_remove_cardee_from_wallet_title: 'Eliminar Cardee',
        popup_remove_cardee_from_wallet_question: '¿De verdad deseas eliminar esta Cardee de tu Cartera?',
        popup_scan_qr_code_title: 'Escanear código QR',
        popup_scan_qr_code_question: '¿De verdad deseas escanear un código QR?',
        popup_camera_grant_permission_title: 'Dar permiso de accceso a la cámara', 
        popup_camera_grant_permission_question: 'Si deseas dar permiso para usar tu cámara, actívalo en la configuración de la aplicación.',
        popup_grant_permission_scan_qr_codes_title: 'Dar permiso',
        popup_grant_permission_scan_qr_codes_question: 'Necesitamos tu permiso para utilizar la cámara de tu teléfono y poder escanear códigos QR',
        popup_deactivate_account_title: 'Desactivar cuenta',
        popup_deactivate_account_question: '¿Confirmas que deseas desactivar tu cuenta?',
        popup_delete_account_title: 'Eliminar cuenta',
        popup_delete_account_question: '¿Confirmas que deseas eliminar tu cuenta?',
        
        change_password_title: 'Cambiar contraseña',
        change_password_old: 'Contraseña anterior',
        change_password_old_placeholder: 'Tu contraseña anterior',
        change_password_new: 'Nueva contraseña',
        change_password_new_placeholder: 'Tu nueva contraseña',
        change_password_retype: 'Repite la contraseña',
        change_password_retype_placeholder: 'Repite tu nueva contraseña',
        change_password_passwords_do_not_match: 'Las contraseñas nuevas no coinciden',
        change_password_btn_save_new_password: 'Guardar nueva contraseña',
        change_password_changes_saved: 'Tu nueva contraseña ha sido guardada.',
        change_password_this_field_is_required: 'Se requiere este campo',
        
        profile_page_me: 'Yo',
        profile_page_edit_profile: 'Editar perfil',
        profile_page_friends: 'Amigos',
        profile_page_leads: 'Contactos',
        profile_page_deals: 'Acuerdos',
        profile_page_my_bio: 'Mi biografía',
        profile_page_city: 'Ciudad',
        profile_page_country: 'País',
        profile_page_workplace: 'Lugar de trabajo',
        profile_page_what_i_love: 'Lo que me gusta',
        profile_page_my_web: 'Mi sitio web',
        
        profile_editor_title: 'Configuración de perfil',
        profile_editor_btn_change_photo: 'Cambiar foto',
        profile_editor_btn_add_photo: 'Agregar foto',
        profile_editor_btn_remove_photo: 'Eliminar foto',
        profile_editor_btn_save_profile: 'Guardar perfil',
        profile_editor_display_name: 'Nombre para mostrar',
        profile_editor_display_name_placeholder: 'Tu nombre para mostrar en público',
        profile_editor_presentation: 'Presentación',
        profile_editor_presentation_placeholder: 'Escribe algo interesante sobre ti',
        profile_editor_city_where_you_live: 'Ciudad donde vives',
        profile_editor_city_where_you_live_placeholder: 'Una bonita ciudad en el mundo',
        profile_editor_country: 'País',
        profile_editor_country_placeholder: 'País de residencia',
        profile_editor_workplace: 'Lugar de trabajo',
        profile_editor_workplace_placeholder: 'Algún lugar para ser productivo',
        profile_editor_what_i_love: '¿Qué es lo que más te gusta?',
        profile_editor_what_i_love_placeholder: 'Cualquier hobby o actividad',
        profile_editor_web_page_or_link: 'Página web o enlace',
        profile_editor_web_page_or_link_placeholder: 'Cualquier enlace de perfil o blog',
        profile_editor_this_field_is_required: 'Se requiere este campo',
        profile_editor_changes_saved: 'Tus cambios han sido guardados.',
        
        settings_notifications_title: 'Configuración de notificaciones',
        settings_notifications_notifications: 'Notificaciones',
        settings_notifications_contact_notification: 'Notificación de contacto',
        settings_notifications_app_updates: 'Actualizaciones de la aplicación',
        settings_notifications_news_and_promotion: 'Noticias y promociones',
        settings_notifications_new_functions_available: 'Nuevas funciones disponibles',
        settings_notifications_changes_saved: 'Tus cambios han sido guardados.',
        
        account_settings_title: 'Configuración de cuenta',
        account_settings_personal_information: 'Información personal',
        account_settings_language: 'Idioma',
        account_settings_synchronize_contacts: 'Sincronizar contactos',
        account_settings_delete_account: 'Eliminar cuenta',
        
        personal_information_title: 'Información personal',
        personal_information_edit_title: 'Editar información',
        personal_information_username: 'Nombre de usuario',
        personal_information_username_placeholder: 'Ingresa tu nombre de usuario',
        personal_information_full_name: 'Nombre completo',
        personal_information_full_name_placeholder: 'Ingresa tu nombre completo',
        personal_information_first_name: 'Nombre',
        personal_information_first_name_placeholder: 'Ingresa tu nombre',
        personal_information_last_name: 'Apellido',
        personal_information_last_name_placeholder: 'Ingresa tu apellido',
        personal_information_email: 'Correo electrónico',
        personal_information_email_placeholder: 'Ingresa tu dirección de correo electrónico',
        personal_information_mobile: 'Teléfono móvil',
        personal_information_mobile_placeholder: 'Ingresa tu número de teléfono móvil',
        personal_information_birthday: 'Fecha de nacimiento',
        personal_information_birthday_placeholder: 'Ingresa tu fecha de nacimiento',
        personal_information_country: 'País',
        personal_information_country_placeholder: 'País de residencia',
        personal_information_this_field_is_required: 'Se requiere este campo',
        personal_information_btn_edit_personal_data: 'Editar datos personales',
        personal_information_btn_save_personal_data: 'Guardar datos personales',
        
        settings_language_title: 'Idioma',
        settings_language_description: 'Elige tus preferencias de idioma',
        settings_language_main_language: 'Idioma principal',
        settings_language_translator: 'Traductor',
        
        settings_delete_account_title: 'Eliminar cuenta',
        settings_delete_account_title_long: '¿Desactivar o eliminar tu cuenta?',
        settings_delete_account_deactivation: 'La desactivación de la cuenta es temporal.',
        settings_delete_account_deactivation_description: 'Tu perfil, tarjetas, mensajes e información guardada estarán ocultos hasta que la vuelvas a activar o inicies sesión nuevamente.',
        settings_delete_account_deletion: 'La eliminación de la cuenta es permanente.',
        settings_delete_account_deletion_description: 'Tu perfil, tarjetas, mensajes e información guardada se eliminarán por completo.',
        settings_delete_account_btn_deactivate_account: 'Desactivar cuenta',
        settings_delete_account_btn_delete_account: 'Eliminar cuenta',
        
        settings_account_has_been_deactivated: 'Tu cuenta ha sido desactivada.',
        settings_account_has_been_deleted: 'Tu cuenta ha sido eliminada.',
        
        help_page_review_title: '¿Qué te parece Cardee?',
        help_page_review_star1_title: '¡Lo sentimos mucho!',
        help_page_review_star1_description: 'Comparte tus impresiones, haremos todo lo posible para mejorar.',
        help_page_review_star2_title: '¿Estamos haciendo algo mal?',
        help_page_review_star2_description: 'Cuéntanos qué no te gusta y trabajaremos para mejorarlo.',
        help_page_review_star3_title: '¡Aparentemente no te convence!',
        help_page_review_star3_description: 'Dinos qué podemos hacer para que te sientas mejor.',
        help_page_review_star4_title: '¡Parece que estamos haciendo las cosas bien!',
        help_page_review_star4_description: '¡Pero siempre podemos hacerlo mejor! Muchas gracias.',
        help_page_review_star5_title: 'Es increíble lo que hacemos juntos',
        help_page_review_star5_description: 'Muchas gracias, queremos seguir mejorando, ayúdanos un poco.',
        help_page_review_btn_help_us_to_improve: 'Ayúdanos a mejorar',
        help_page_help_us_to_improve_header: 'Ayúdanos a mejorar',
        help_page_help_us_to_improve_note: '¿Qué te gustaría más?',
        help_page_help_us_to_improve_title: 'Siempre podemos hacerlo mejor.',
        help_page_take_the_survey: 'Realizar la encuesta',
        help_page_share_and_win_header: '¡Comparte y gana!',
        help_page_share_and_win_note: 'Invita a tus amigos y obtén más.',
        help_page_invite_5: 'Invitar a 5',
        help_page_win_6: '¡Ganar 6!',
        help_page_invite_your_friens: 'Invita a tus amigos y conviértete en un',
        help_page_pro: 'Pro',
        help_page_share_with_your_friends: 'Comparte con tus amigos.',
        help_page_ad_smart_networking: '#SmartNetworking',
        help_page_ad_text_line_1: 'Fácil de crear',
        help_page_ad_text_line_2: 'Fácil de compartir',
        help_page_ad_text_line_3: 'Fácil de guardar',
        help_page_ad_without_a_doubt_it_is: 'Sin duda es',
        help_page_ad_lets_connect: '¡Vamos a conectarnos!',
        
        notifications_title: 'Notificaciones',

        photo_gallery_choose_a_photo: 'Escoge una foto',
        photo_gallery_camera_roll: 'Fotos en la cámara',
        photo_gallery_albums: 'Álbumes',
        photo_gallery_cardee_photos: 'Fotos de Cardee',
        photo_gallery_chose_your_company_logo: 'Escoge el logo de tu empresa',
        photo_gallery_selected_album: 'Álbum seleccionado:',
        photo_gallery_take_your_photo: 'Toma tu foto',
        photo_gallery_for_best_results: 'Para obtener mejores resultados',
        photo_gallery_prompt_photo: 'Foto',
        photo_gallery_prompt_cancel: 'Cancelar',
        photo_gallery_prompt_from_gallery: 'De la Galería',
        photo_gallery_prompt_take_picture: 'Tomar Foto',
        
        error_something_went_wrong: '¡Algo salió mal!',
        error_could_not_reach_the_server: 'No se pudo conectar con el servidor.',
        error_max_cards_limit_reached: 'Se ha alcanzado el número máximo de Cardees.',
        error_permission_denied: '¡Permiso denegado!',

        error_invalid_email: 'Correo no válido',
        error_invalid_phone_number: 'Número de teléfono no válido',
        error_login_btn_label: 'Ingresar',
        error_dismiss_btn_label: 'Cerrar',
        error_close_btn_label: 'Cerrar',
        error_no_permission_to_this_operation: 'No tienes permiso para realizar esta operación.',

        information_title: 'Información',
        information_app_version: 'App versión',
        information_compilation: 'Compilación',
        information_copyright: 'Copyright 2023',
        information_company_name: 'Cardee Technologies, SL',
        information_all_rights_reserved: 'Reservados todos los derechos',

        privacy_description: 'Cardee Technologies, SL. aplica la política de cumplir con todas las leyes de privacidad y protección de datos aplicables. Este compromiso refleja el valor que otorgamos a la confianza de nuestros clientes, socios comerciales y otras personas que comparten su información personal con nosotros.',
        privacy_btn_label: 'Privacidad de Cardee',

        its_ok_btn_label: '¡Está bien!',
    },
};
