import { useAppState } from '@/appState';

export async function getLocalStorageValue(key: string) {
    const appState = useAppState();
    return await appState.localStorage.get(key);
}

export async function saveLocalStorageValue(key: string, value: string) {
    const appState = useAppState();
    await appState.localStorage.set(key, value);
}

export async function createThrottledSetLocalStorageData(saveFn: (formData: any) => void) {
    let storeTimer: any = null;

    return async function(formData: any) {
        if (storeTimer === null) {
            storeTimer = await setTimeout(async () => {
                await saveFn(formData);
                storeTimer = null;
            }, 500);
        }
    }
}
