import { App, URLOpenListenerEvent } from '@capacitor/app';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
//import TabsPage from '@/views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'app_start',
    component: () => import('@/views/AppStartPage.vue')
  },
  {
    path: '/login',
    name: 'app_login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/register',
    name: 'app_register',
    component: () => import('@/views/RegistrationPage.vue')
  },
  {
    path: '/reset_password',
    name: 'app_reset_password',
    component: () => import('@/views/ResetPasswordPage.vue')
  },

  //tabs with nested routes were removed because we have to reach almost every page within every tabs
  /*{
    path: '/tabs/',
    component: TabsPage,
    children: [
  */  {
        //path: '',
        path: '/tabs/',
        redirect: '/tabs/tab_home'
      },
      {
        path: '/tabs/tab_home',
        name: 'app_home',
        component: () => import('@/views/tabs/TabHomePage.vue')
      },
      {
        path: '/tabs/tab_home/add_cardee',
        name: 'app_home_add_cardee',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: '/tabs/tab_home/edit_cardee/:cardId',
        name: 'app_home_edit_cardee',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: '/tabs/tab_home/cardee/:cardHandle',
        name: 'app_home_cardee_page',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_home/cardee/id/:cardId',
        name: 'app_home_cardee_page_with_id',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_home/otherscardee/:cardHandle',
        name: 'app_home_otherscardee_page',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_home/otherscardee/id/:cardId',
        name: 'app_home_otherscardee_page_with_id',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_wallet',
        name: 'tab_wallet',
        component: () => import('@/views/tabs/TabWalletPage.vue')
      },
      {
        path: '/tabs/tab_wallet/add_cardee',
        name: 'tab_wallet_add_cardee',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: '/tabs/tab_wallet/edit_cardee/:cardId',
        name: 'tab_wallet_edit_cardee',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: '/tabs/tab_wallet/cardee/:cardHandle',
        name: 'tab_wallet_cardee_page',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_wallet/cardee/id/:cardId',
        name: 'tab_wallet_cardee_page_with_id',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_wallet/otherscardee/:cardHandle',
        name: 'tab_wallet_otherscardee_page',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_wallet/otherscardee/id/:cardId',
        name: 'tab_wallet_otherscardee_page_with_id',
        component: () => import('@/views/cardee/CardeePage.vue')
      },
      {
        path: '/tabs/tab_add_cardee',
        name: 'tab_add_cardee',
        component: () => import('@/views/tabs/TabAddCardPage.vue')
      },
      {
        path: '/tabs/tab_add_cardee/add_cardee',
        name: 'tab_add_cardee_add_cardee',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: '/tabs/tab_add_cardee/edit_cardee/:cardId',
        name: 'tab_add_cardee_edit_cardee',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: '/tabs/tab_more',
        name: 'tab_more',
        component: () => import('@/views/tabs/TabHelpUsPage.vue')
      },
      {
        path: '/tabs/tab_settings',
        name: 'tab_settings',
        component: () => import('@/views/tabs/TabSettingsPage.vue')
      },
  /*  ]
  },*/
  /*
  {
    path: '/cardee/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab_home'
      },
      {
        path: 'add_cardee',
        name: 'pseudotab_cardee_add',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: 'edit_cardee/:cardId',
        name: 'pseudotab_cardee_edit',
        component: () => import('@/views/cardee/AddCardeePage.vue')
      },
      {
        path: 'detail/:cardId',
        name: 'cardee_page',
        component: () => import('@/views/cardee/CardeePage.vue')
      }
    ]
  },
  */
  {
    path: '/cardee/:cardHandle',
    name: 'cardee_page',
    component: () => import('@/views/cardee/CardeePage.vue')
    /*
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/cardee/detail/' + to.params.cardId }
    }
    */
  },
  {
    path: '/cardee/id/:cardId',
    name: 'cardee_page_with_id',
    component: () => import('@/views/cardee/CardeePage.vue')
    /*
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/cardee/detail/' + to.params.cardId }
    }
    */
  },
  {
    path: '/otherscardee/:cardHandle',
    name: 'otherscardee_page',
    component: () => import('@/views/cardee/CardeePage.vue')
    /*
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/cardee/detail/' + to.params.cardId }
    }
    */
  },
  {
    path: '/otherscardee/id/:cardId',
    name: 'otherscardee_page_with_id',
    component: () => import('@/views/cardee/CardeePage.vue')
    /*
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/cardee/detail/' + to.params.cardId }
    }
    */
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  const contentStr = new URL(event.url).pathname;

  if (contentStr) {
    const cardHandle = contentStr?.substring('/vcard/'.length).split('/')?.[0];
    if (cardHandle) {
      router.push({ name: 'tab_wallet_otherscardee_page', params: {cardHandle: cardHandle} });
    }
  }
});

export default router
