import { useAppState } from './appState';

let runAfterLoading: Array<VoidFunction> = [];
let pendingRunAfterLoading: Array<VoidFunction> = [];

export const onStorageReady = (cb: VoidFunction) => {
  const { appAlreadyLoaded, isAfterLoadingRunning } = useAppState();

  if (isAfterLoadingRunning) {
    pendingRunAfterLoading.push(cb);
  } else {
    runAfterLoading.push(cb);
  }

  if (appAlreadyLoaded) {
    runOnStorageReady();
  }
};

export const runOnStorageReady = () => {
  const { setAppAlreadyLoaded, setIsAfterLoadingRunning } = useAppState();
  setIsAfterLoadingRunning(true);
  Promise.all(runAfterLoading.map(cb => cb()))
    .then(() => {
      setAppAlreadyLoaded(true);
      setIsAfterLoadingRunning(false);

      if (pendingRunAfterLoading.length) {
        runAfterLoading = pendingRunAfterLoading;
        pendingRunAfterLoading = [];
        runOnStorageReady();
      }
    });
};
