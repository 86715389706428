export interface BackgroundColors {
		'c1'?: {main?: string, second?: string, third?: string},
		'c2'?: {main?: string, second?: string, third?: string},
		'c3'?: {main?: string, second?: string, third?: string},
		'c4'?: {main?: string, second?: string, third?: string},
		'c5'?: {main?: string, second?: string, third?: string},
    'c6'?: {main?: string, second?: string, third?: string},
    'c7'?: {main?: string, second?: string, third?: string},

		'c_oceanblue'?: {main?: string, second?: string, third?: string},
		'c_ragingred'?: {main?: string, second?: string, third?: string},
		'c_midnightorchid'?: {main?: string, second?: string, third?: string},
		'c_honeyglow'?: {main?: string, second?: string, third?: string},
		'c_freshforest'?: {main?: string, second?: string, third?: string},
    'c_peachypunch'?: {main?: string, second?: string, third?: string},
    'c_mysticblack'?: {main?: string, second?: string, third?: string},
}
export interface BackgroundPatterns {
		'p1'?: string,
		'p2'?: string,
		'p3'?: string,
		'p4'?: string,
		'p5'?: string,
		'p6'?: string,

		'p_tech'?: string,
		'p_diagonal'?: string,
		'p_lightertiles'?: string,
		'p_lighttiles'?: string,
		'p_lightesttiles'?: string,
		'p_romboids'?: string,
}
export interface SocialApps {
    'social01Value'?: string,
    'social02Value'?: string,
    'social03Value'?: string,
    'social04Value'?: string,
    'social05Value'?: string,
    'social06Value'?: string,
    'social07Value'?: string,
    'social08Value'?: string,
}
export interface SocialAppsForView {
    'social01ValueForView'?: string,
    'social02ValueForView'?: string,
    'social03ValueForView'?: string,
    'social04ValueForView'?: string,
    'social05ValueForView'?: string,
    'social06ValueForView'?: string,
    'social07ValueForView'?: string,
    'social08ValueForView'?: string,
}
export interface SocialAppsForLink {
    'social01ValueForLink'?: string,
    'social02ValueForLink'?: string,
    'social03ValueForLink'?: string,
    'social04ValueForLink'?: string,
    'social05ValueForLink'?: string,
    'social06ValueForLink'?: string,
    'social07ValueForLink'?: string,
    'social08ValueForLink'?: string,
}

export const appConfig = {
	//apiUrl: 'http://localhost:8000',
  apiUrl: 'https://api.cardee.me',
  qrCodeBaseUrl: 'https://web.cardee.me',
  appVersion: '1.0.0',
  appCompilation: '20231102-M0001',

  appMaxCardsLimit: 3,

  externalLinks: {
    survey: 'https://www.cardee.me/app/encuesta/',
    faq: 'https://www.cardee.me/app/faqs/',
    support: 'https://www.cardee.me/app/soporte/',
    info: 'https://www.cardee.me/legal/terminos-condiciones/',
    privacy: 'https://www.cardee.me/legal/privacidad/',
  },

  emptyBackgroundColor: 'c_none',
  emptyBackgroundPattern: 'p_none',

	backgroundColors: {
		'c1': {main: '#1472B3', second: '#59ABE4', third: '#130847'},
		'c2': {main: '#DD0B0B', second: '#FC4C4C', third: '#370101'},
		'c3': {main: '#461EBD', second: '#7A55E9', third: '#1B0363'},
		'c4': {main: '#FFC107', second: '#FEDB72', third: '#413100'},
		'c5': {main: '#00974E', second: '#10D777', third: '#011E10'},
    'c6': {main: '#FB5E1C', second: '#FD8654', third: '#250B01'},
    'c7': {main: '#434343', second: '#87878E', third: '#000000'},

    'c_none': {main: '', second: '', third: ''},

		'c_oceanblue': {main: '#1472B3', second: '#59ABE4', third: '#130847'},
		'c_ragingred': {main: '#DD0B0B', second: '#FC4C4C', third: '#370101'},
		'c_midnightorchid': {main: '#461EBD', second: '#7A55E9', third: '#1B0363'},
		'c_honeyglow': {main: '#FFC107', second: '#FEDB72', third: '#413100'},
		'c_freshforest': {main: '#00974E', second: '#10D777', third: '#011E10'},
    'c_peachypunch': {main: '#FB5E1C', second: '#FD8654', third: '#250B01'},
    'c_mysticblack': {main: '#434343', second: '#87878E', third: '#000000'},
	} as BackgroundColors,

	backgroundPatterns: {
		'p1': 'bg-001-preview.jpg',
		'p2': 'bg-001b-preview.jpg',
		'p3': 'bg-002-preview.jpg',
		'p4': 'bg-003-preview.jpg',
		'p5': 'bg-004-preview.jpg',
		'p6': 'bg-005-preview.jpg',
    
    'p_none': '',

		'p_tech': 'bg-001-preview.jpg',
		'p_diagonal': 'bg-001b-preview.jpg',
		'p_lightertiles': 'bg-002-preview.jpg',
		'p_lighttiles': 'bg-003-preview.jpg',
		'p_lightesttiles': 'bg-004-preview.jpg',
		'p_romboids': 'bg-005-preview.jpg',
	} as BackgroundPatterns,

  socialApps: {
    'social01Value': 'facebook',
    'social02Value': 'xtwitter',
    'social03Value': 'instagram',
    'social04Value': 'youtube',
    'social05Value': 'linkedin',
    'social06Value': 'tiktok',
    'social07Value': 'telegram',
    'social08Value': 'pinterest',
  } as SocialApps,

  socialAppsForView: {
    'social01ValueForView': 'facebook',
    'social02ValueForView': 'xtwitter',
    'social03ValueForView': 'instagram',
    'social04ValueForView': 'youtube',
    'social05ValueForView': 'linkedin',
    'social06ValueForView': 'tiktok',
    'social07ValueForView': 'telegram',
    'social08ValueForView': 'pinterest',
  } as SocialAppsForView,

  socialAppsForLink: {
    'social01ValueForLink': 'facebook',
    'social02ValueForLink': 'xtwitter',
    'social03ValueForLink': 'instagram',
    'social04ValueForLink': 'youtube',
    'social05ValueForLink': 'linkedin',
    'social06ValueForLink': 'tiktok',
    'social07ValueForLink': 'telegram',
    'social08ValueForLink': 'pinterest',
  } as SocialAppsForLink,

  commonRequestHeaders: {
    //removed because they are not allowed by preflight
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
    //'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS',
  },
};
