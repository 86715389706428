import { getLocalStorageValue, saveLocalStorageValue } from "./utils";

async function getLocalStorageData(formData: any) {
    return await Promise.all([
        getLocalStorageValue('local_datas.authenticated_user'),
])
    .then((res) => {
        const [
            authenticatedUser
        ] = res;

        formData.authenticatedUser = JSON.parse(authenticatedUser);
    });
}

async function setLocalStorageData(formData: any) {
    return await Promise.all([
            saveLocalStorageValue('local_datas.authenticated_user', JSON.stringify(formData)),
    ]);
}
export {
    getLocalStorageData,
    setLocalStorageData
};