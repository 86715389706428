
  import { IonIcon, IonButton, IonFooter, IonContent, IonToolbar, modalController } from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { closeOutline } from 'ionicons/icons';

  export default defineComponent({
    name: 'ModalComingSoon',
    components: { IonIcon, IonButton, IonFooter, IonContent, IonToolbar },
    props: {
        title: {
          type: String,
          default: 'Empty'
        },
        name: {
          type: String,
          default: 'Not set'
        }
    },
    data() {
      return {
        message: 'This modal example uses triggers to automatically open a modal when the button is clicked.',
      };
    },
    methods: {
      cancel() {
        return modalController.dismiss(null, 'cancel');
      },
      confirm(value: any) {
        return modalController.dismiss(value, 'confirm');
      },
    },
    setup() {

      //const input = ref('');

      return { closeOutline }
    }
  });
