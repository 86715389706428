import { useAppState } from './appState';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { appConfig } from '@/appConfig';
import { toastController, ToastOptions, alertController, modalController, AlertOptions, createAnimation } from '@ionic/vue';
import { checkmarkCircleOutline, logInOutline, closeOutline, closeCircleOutline, informationCircleOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { getLocalStorageData as getLocalStorageLoginData, setLocalStorageData as setLocalStorageLoginData } from '@/data/localStorage/authenticatedUser';
import ModalComingSoon from '@/components/modals/ModalComingSoon.vue';

const { apiUrl, commonRequestHeaders } = appConfig;

export type ErrorCallBackFnType = (e: any) => void;

async function setAlreadyRunOnce() {
  const appState = useAppState();
  await appState.localStorage.set('AlreadyRunOnce', true);
}

async function getAPI(urlEndPoint: string, dataInput?: any, errCb?: ErrorCallBackFnType) {
  let res: any = {};
  let exceptionOccurred = false;
  let fetchException:any = null;
  try {
    const requestObj: any = {
      url: `${apiUrl}${urlEndPoint}`,
      headers: {
        ...commonRequestHeaders,
        'Content-Type': 'application/json',
      },
      webFetchExtra: {
        credentials: 'include',
      },
    };
    if (dataInput) {
      requestObj.data = JSON.stringify(dataInput);
    }

    const result: HttpResponse = await CapacitorHttp.get(requestObj);
    res = result;
    try {
      res.data = JSON.parse(res.data);
    }
    catch (ex) { 
      //console.log(ex);
    }
    console.log('getAPI:', urlEndPoint);
    console.log('getAPI:', res);
  } catch (e) {
    exceptionOccurred = true;
    fetchException = e;
    console.log('ERROR getAPI:', urlEndPoint);
    console.log('ERROR getAPI:', e);
  }

  if (exceptionOccurred) {
    errCb?.(fetchException);
    //return { status: 500, data: null };
    return { status: 500, data: fetchException };
  }
  const { status, data = {} } = res ?? {};

  if (status === 403) {
    const appState = useAppState();
    appState.setUser({});
  }
  return { status, data };
}

async function postAPI(urlEndPoint: string, dataInput?: any, errCb?: ErrorCallBackFnType) {
  let res: any = {};
  let exceptionOccurred = false;
  let fetchException:any = null;
  try {
    const requestObj: any = {
      url: `${apiUrl}${urlEndPoint}`,
      headers: {
        ...commonRequestHeaders,
        'Content-Type': 'application/json',
      },
      webFetchExtra: {
        credentials: 'include',
      },
    };
    if (dataInput) {
      requestObj.data = JSON.stringify(dataInput);
    }

    const result: HttpResponse = await CapacitorHttp.post(requestObj);
    res = result;
    try {
      res.data = JSON.parse(res.data);
    }
    catch (ex) { 
      //console.log(ex);
    }
    console.log('postAPI:', urlEndPoint);
    console.log('postAPI:', res);
  } catch (e) {
    exceptionOccurred = true;
    fetchException = e;
    console.log('ERROR postAPI:', urlEndPoint);
    console.log('ERROR postAPI:', e);
  }

  if (exceptionOccurred) {
    errCb?.(fetchException);
    //return { status: 500, data: null };
    return { status: 500, data: fetchException };
  }
  const { status, data = {} } = res ?? {};

  if (status === 403) {
    const appState = useAppState();
    appState.setUser({});
  }
  return { status, data };
}

async function postMultipartAPI(urlEndPoint: string, dataInput?: any, errCb?: ErrorCallBackFnType) {
  let res: any = {};
  let exceptionOccurred = false;
  let fetchException:any = null;
  try {
    const requestObj: any = {
      url: `${apiUrl}${urlEndPoint}`,
      headers: {
        ...commonRequestHeaders,
        'Content-Type': 'multipart/form-data; boundary=----------1234567890',
      },
      webFetchExtra: {
        credentials: 'include',
      },
      dataType: 'formData',
    };
    if (dataInput) {
      requestObj.data = dataInput;
    }
    console.log('postMultipartAPI with fetch() dataInput:', dataInput);
    console.log('postMultipartAPI with fetch() requestObj:', JSON.stringify(requestObj));

    //const result: HttpResponse = await CapacitorHttp.post(requestObj);
    const result: Response = await fetch(requestObj.url, {method: 'POST', body: requestObj.data, credentials: requestObj.webFetchExtra.credentials});
    res = result;
    try {
      //res.data = JSON.parse(res.data); //use it only with CapacitorHttp.post
      res.data = await res.json(); //use with fetch
    }
    catch (ex) { 
      console.log(ex);
    }
    console.log('postMultipartAPI with fetch():', urlEndPoint);
    console.log('postMultipartAPI with fetch():', res);
  } catch (e) {
    exceptionOccurred = true;
    fetchException = e;
    console.log('ERROR postMultipartAPI with fetch():', urlEndPoint);
    console.log('ERROR postMultipartAPI with fetch():', e);
  }

  if (exceptionOccurred) {
    errCb?.(fetchException);
    //return { status: 500, data: null };
    return { status: 500, data: fetchException };
  }
  const { status, data = {} } = res ?? {};

  if (status === 403) {
    const appState = useAppState();
    appState.setUser({});
  }
  return { status, data };
}

async function deleteAPI(urlEndPoint: string, dataInput?: any, errCb?: ErrorCallBackFnType) {
  let res: any = {};
  let exceptionOccurred = false;
  let fetchException:any = null;
  try {
    const requestObj: any = {
      url: `${apiUrl}${urlEndPoint}`,
      headers: {
        ...commonRequestHeaders,
        'Content-Type': 'application/json',
      },
      webFetchExtra: {
        credentials: 'include',
      },
    };
    if (dataInput) {
      requestObj.data = JSON.stringify(dataInput);
    }

    const result: HttpResponse = await CapacitorHttp.delete(requestObj);
    res = result;
    try {
      res.data = JSON.parse(res.data);
    }
    catch (ex) { 
      //console.log(ex);
    }
    console.log('deleteAPI:', urlEndPoint);
    console.log('deleteAPI:', res);
  } catch (e) {
    exceptionOccurred = true;
    fetchException = e;
    console.log('ERROR deleteAPI:', urlEndPoint);
    console.log('ERROR deleteAPI:', e);
  }

  if (exceptionOccurred) {
    errCb?.(fetchException);
    //return { status: 500, data: null };
    return { status: 500, data: fetchException };
  }
  const { status, data = {} } = res ?? {};

  if (status === 403) {
    const appState = useAppState();
    appState.setUser({});
  }
  return { status, data };
}

//async function presentToast(message: string, color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark', position: 'top' | 'middle' | 'bottom', duration: number ) {
async function presentToast(options: ToastOptions, t?: any, displayLoginButton?: boolean) {
  if (displayLoginButton) {
    const isUserLoggedIn = async (errCb?: ErrorCallBackFnType) => {      
      const { status, data } = await postAPI('/login/check', null, errCb);

      if (status === 500)
      {
        return false;
      }

      return !!(data?.result);
    };
    const areWeLoggedIn = await isUserLoggedIn();
    if (!areWeLoggedIn) {
      options.duration = 6000;
      options.buttons = [
        {
          text: t('error_login_btn_label'),
          icon: logInOutline,
          role: 'go_to_login_page',
          side: 'end',
        },
        {
          //text: t('error_dismiss_btn_label'),
          icon: closeOutline,
          role: 'cancel',
          side: 'end',
        }
      ];

      if (!options.layout) {
        options.layout = 'stacked';
      }
    }
  }

  //console.log('presentToast', options);
  const toast = await toastController.create({
    header: options.header ?? undefined,
    message: options.message ?? '',
    color: options.color ?? 'success',
    duration: options.duration ?? 2000,
    position: options.position ?? 'bottom',
    icon: options.buttons ? undefined : (options?.color === 'success' ? checkmarkCircleOutline : (options?.color && (['warning', 'danger']).includes(options?.color) ? closeCircleOutline : informationCircleOutline)),
    buttons: options.buttons ?? [{
      //text: t('error_dismiss_btn_label'),
      icon: closeOutline,
      role: 'cancel',
      side: 'end',
    }],
    layout: options.layout ?? 'baseline',
    //cssClass: options.cssClass ?? (options.buttons ? 'ion-toast-with-icon' : undefined),
    cssClass: options.cssClass ?? undefined,
  });

  await toast.present();

  if (displayLoginButton) {
    const { role } = await toast.onDidDismiss();
    if (role === 'go_to_login_page') {
      const appState = useAppState();
      appState.router.replace({ name: 'app_login' });
    }
  }

  return toast;
}

const enterAnimation = (baseEl: HTMLElement | undefined) => {
        if (!baseEl) return;

        const root = baseEl.shadowRoot;
        if (!root) return;

        const rootIonBackdrop = root.querySelector('ion-backdrop');
        if (!rootIonBackdrop) return;

        const rootDotModalWrapper = root.querySelector('.modal-wrapper');
        if (!rootDotModalWrapper) return;

        const backdropAnimation = createAnimation()
          .addElement(rootIonBackdrop)
          .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');
        const wrapperAnimation = createAnimation()
          .addElement(rootDotModalWrapper)
          //.keyframes([
          //  { offset: 0, opacity: '0', transform: 'scale(0)' },
          //  { offset: 1, opacity: '0.99', transform: 'scale(1)' },
          //])
          .fromTo('opacity', '0.99', '0.99')
          .fromTo('transform', 'translateX(-100vw)', 'translateX(0px)');

        return createAnimation()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(350)
          .addAnimation([backdropAnimation, wrapperAnimation]);
};

const leaveAnimation = (baseEl: HTMLElement) => {
        if (!baseEl) return;

        const newAnimation = enterAnimation(baseEl);
        if (!newAnimation) return;

        return newAnimation.direction('reverse');
};


const comingSoonAlert = async (options?: AlertOptions) => {
  const { t } = useI18n({});
  const alert = await alertController.create({
    header: options?.header ?? t('coming_soon'),
    subHeader: options?.subHeader ?? t('under_development'),
    message: options?.message ?? t('this_feature_under_development'),
    cssClass: 'custom-alert',
    buttons: [{
      text: 'OK',
      role: 'cancel',
    }],
  });

  await alert.present();
};

const openModalComingSoon = async () => {
  const modal = await modalController.create({
    component: ModalComingSoon,
    id: 'blue-gradient',
    cssClass: 'lighter',
    //componentProps: { title: ''},
    //enterAnimation: enterAnimation as AnimationBuilder,
    //leaveAnimation: leaveAnimation as AnimationBuilder
  });
  await modal.present();
};
        
const imageExtentions = ['jpg', 'jpeg', 'gif', 'png'];

const imageMimeTypes = {jpg: 'jpeg', jpeg: 'jpeg', gif: 'gif', png: 'png'} as any;

const getFileExtension = (fileName: string) => {
      if (fileName?.startsWith('data:')) {
        const arr = fileName.split(','),
              mime = arr?.[0].match(/:(.*?);/)?.[1];
        return mime?.split('/')?.pop() ?? '';
      }
      return fileName?.split('.')?.pop() ?? '';
};

const isDataImageOrFile = (url: string) => {
        return (url?.startsWith('file://') || url?.startsWith('data:image'));
};

const getImageNameExtensionAndMimeType = (urlOrData: string, newName?: string) => {
        //const { imageExtentions, imageMimeTypes, getFileExtension } = useAppUtils();
        
        const newFileExtLower = getFileExtension(urlOrData).toLowerCase();
        const imgExt = imageExtentions.includes(newFileExtLower) ? newFileExtLower : 'jpeg';
        const newFileName = `${(newName || 'profile_image')}.${imgExt}`;
        const newFileMimeType = `image/${imageMimeTypes?.[imgExt] ?? 'jpeg'}`;
        return {name: newFileName, ext: imgExt, mimeType: newFileMimeType} as any;
};

const imageUrlToBase64 = async (url: any) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((onSuccess, onError) => {
    try {
      const reader = new FileReader() ;
      reader.onload = function(){ onSuccess(this.result) } ;
      reader.readAsDataURL(blob) ;
    } catch(e) {
      onError(e);
    }
  });
};

// return a promise that resolves with a File instance
const urltoFile = async (url: string, filename: string, mimeType?: string) => {
        if (url.startsWith('data:')) {
            const arr = url.split(','),
                  mime = arr?.[0].match(/:(.*?);/)?.[1],
                  bstr = atob(arr[arr.length - 1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while(n--){
              u8arr[n] = bstr.charCodeAt(n);
            }
            const file = new File([u8arr], filename, {type: mime || mimeType});
            return Promise.resolve(file);
        }
        else if (url.startsWith('file:')) {
            const fileFromPath = await Filesystem.readFile({
              path: url,
            });
            const base64Data = fileFromPath.data;
            
            const newFile = getImageNameExtensionAndMimeType(url);
            const newUrl = `data:${newFile.mimeType};base64,${base64Data}`;

            const arr = newUrl.split(','),
                  mime = arr?.[0].match(/:(.*?);/)?.[1],
                  bstr = atob(arr[arr.length - 1]);
            //console.log('urltoFile file: 2', bstr);
            console.log('urltoFile file: 3', mime);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while(n--){
              u8arr[n] = bstr.charCodeAt(n);
            }
            const file = new File([u8arr], filename, {type: mime || mimeType});
            return Promise.resolve(file);
        }
        return fetch(url)
          .then(res => res.arrayBuffer())
          .then(buf => new File([buf], filename, {type: mimeType}));
};

// return a promise that resolves with a base64-encoded format of the binary file instance
const urltoFileBase64 = async (url: string, filename: string, mimeType?: string) => {
        if (url.startsWith('data:')) {
            const arr = url.split(','),
                  mime = arr?.[0].match(/:(.*?);/)?.[1];
            return Promise.resolve(arr[arr.length - 1]);
        }
        else if (url.startsWith('file:')) {
            const fileFromPath = await Filesystem.readFile({
              path: url,
            });
            return Promise.resolve(fileFromPath.data);
        }
        return imageUrlToBase64(url);
};

export const useAppUtils = () => {
  return {
    setAlreadyRunOnce,
    getAPI,
    postAPI,
    postMultipartAPI,
    deleteAPI,
    presentToast,
    enterAnimation,
    leaveAnimation,
    comingSoonAlert,
    openModalComingSoon,
    imageExtentions,
    imageMimeTypes,
    getFileExtension,
    isDataImageOrFile,
    getImageNameExtensionAndMimeType,
    urltoFile,
    urltoFileBase64,

    async goHomeAfterIntro() {
      const appState = useAppState();

      setAlreadyRunOnce();

      appState.setPageToShowOnLoad('home');
      appState.router.replace({ name: 'app_home' });
    },

    async isUserLoggedIn(errCb?: ErrorCallBackFnType, options?: { relogin: boolean }) {      
      const { relogin = true } = options || {}
      const { status, data } = await postAPI('/login/check', null, errCb);

      const isLoggedIn = (status === 500) ? false : !!(data?.result);

      if (!isLoggedIn && relogin) {
        const loginData: any = {};
        await getLocalStorageLoginData(loginData);
        const { authenticatedUser } = loginData || {};
        const { login, password } = authenticatedUser || {};

        if (login && password) {
          const { status, data } = await postAPI('/login_j', {
            'username': login,
            'password': password
          });
          if ((status === 200) && (data.success === true)) {
            return true;
          }

          await setLocalStorageLoginData({});

          return false;
        }
      }

      return isLoggedIn;
    },

    async getUserProfile(errCb?: ErrorCallBackFnType) {
      const { status, data } = await getAPI('/profile/en', null, errCb);

      return { status, data };
    },
  };
};
