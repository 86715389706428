
  import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonRouterOutlet } from '@ionic/vue';
  //import SplashContainer from '@/components/SplashContainer.vue';
  //import AddCardeeChoicesContainer from '@/components/AddCardeeChoicesContainer.vue';
  import IconTabHome from '@/components/icons/IconTabHome.vue';
  import IconTabWallet from '@/components/icons/IconTabWallet.vue';
  import IconTabAdd from '@/components/icons/IconTabAdd.vue';
  import IconTabHelpUs from '@/components/icons/IconTabHelpUs.vue';
  import IconTabSettings from '@/components/icons/IconTabSettings.vue';

  import { onStorageReady } from '@/appRunner';
  import { useAppState } from '@/appState';
  import { useRoute, RouteRecordName } from 'vue-router';
  import { defineComponent, ref, watch } from 'vue';

  const pageStart = async () => {
    const appState = useAppState();
    if (appState.pageToShowOnLoad === 'introduction')
    {
      //removed by Szilard
      //appState.router.replace({ name: 'app_start' });
    }
  }

  export default defineComponent({
    name: 'TabsContainer',
    components: {
      IonTabBar, IonTabButton, IonLabel, IonTabs, IonRouterOutlet,
      //SplashContainer,
      //AddCardeeChoicesContainer,
      IconTabHome,
      IconTabWallet,
      IconTabAdd,
      IconTabHelpUs,
      IconTabSettings,
    },
    data() {
      const appState = useAppState();

      return {
        appState,
        //currentTab: '',
        showTab: false,
      };
    },
    mounted() {
      const thisComponent = this as any;

      setTimeout(function() {
        thisComponent.showTab = true;
      }, 100);

      onStorageReady(pageStart);
    },
    methods: {
      routeNameContains(routeName: string, watchString: string) {
        return routeName.includes(watchString);
      },
      onTabsWillChange({ tab: currentTabName }: { tab: string }) {
        console.log('currentTabName', currentTabName);
      },
    },
    setup() {
      const route = useRoute();
      const routeName = ref<RouteRecordName | null>();

      if (route?.name) {
        routeName.value = route.name;
      }

      watch(
        () => route.name,
        async newName => {
          routeName.value = newName;
        }
      );

      return {
        routeName
      }
    }
  });
